import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DistributionResponse, PeopleResponse, PeopleType } from '../../../models/Distribution';
import Button, { ButtonSize, ButtonType } from '../../shared/form-control/Button';
import useFetchClientUsers from '../../../hooks/useFetchClientUsers';
import { ImageSize } from '../../ownership/ProfileImageStack';
import DistributionService from '../../../services/DistributionService';
import { ModalContext } from '../../../contexts/ModalContext';
import { recordMap, toGroupedRecord } from '../../../utils/ListUtils';
import ConfirmationModal from '../../shared/modal/variants/ConfirmationModal';
import { ListRenderer } from '../../distribution/DistributionMembersStep';
import useFetchClientContacts from '../../../hooks/useFetchClientContacts';
import DistributionStartMessage from '../../distribution/DistributionStartMessage';
import { DocumentResponse } from '../../../models/Document';
import StringUtils from '../../../utils/StringUtils';
import DistributionWizard from '../../distribution/DistributionWizard';
import CogIcon from '../../shared/icon/CogIcon';
import DistributionManageModal from '../../distribution/DistrubtionManageModal';
import Tooltip from '../../shared/Tooltip';
import { useDistributionPermissions } from '../../../hooks/permissions/useDistributionPermissions';
import DistributionMembersDropdown from '../../distribution/DistributionMembersDropdown';
import { useViewDistributionLogs } from '../../../global-state/Forms';
import { useShallow } from 'zustand/react/shallow';

type CondensedDistributionProps = {
  clientForm: DocumentResponse;
  viewOnly?: boolean;
  onDistributionUpdated: (distribution: DistributionResponse) => void;
};

const CondensedDistribution: FC<CondensedDistributionProps> = (props) => {
  const { clientForm, viewOnly, onDistributionUpdated } = props;
  const { t } = useTranslation(['form', 'common', 'distribution']);
  const [showRemoveMemberModal, setShowRemoveMemberModal] = useState<[string | undefined, boolean]>([undefined, false]);
  const [viewDistributionLogs, setViewDistributionLogs] = useViewDistributionLogs(useShallow((x) => [x.value, x.setValue]));
  const [showDistributionModal, setShowDistributionModal] = useState<'create' | 'manage' | false>(false);
  const { canCreateDistribution, canViewDistribution, canMaintainDistribution } = useDistributionPermissions(clientForm.distribution);

  const { data: contacts = [] } = useFetchClientContacts();
  const { data: clientUsers = [] } = useFetchClientUsers();

  const distribution = useMemo(() => clientForm.distribution, [clientForm.distribution]);

  const users = useMemo<PeopleResponse[]>(() => {
    if (!distribution) {
      return [];
    }
    return distribution.members.map((member) => {
      const person =
        member.type === PeopleType.Contact ? contacts.find((x) => x.id === member.memberId) : clientUsers!.find((x) => x.id === member.memberId);
      return {
        id: member.memberId,
        firstName: (person && person.firstName) || '',
        lastName: (person && person.lastName) || '',
        type: member.type,
      } as PeopleResponse;
    });
  }, [clientUsers, contacts, distribution]);

  const sortedUsers = useMemo(() => {
    return (
      users
        // sort on type first, then on full name
        .sort((a, b) => {
          if (a.type !== b.type) {
            return a.type === PeopleType.Member ? -1 : 1;
          }

          return `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`);
        })
    );
  }, [users]);

  const closeConfirmRemoveMember = useCallback(() => setShowRemoveMemberModal([undefined, false]), []);

  const removeMember = useCallback(() => {
    if (distribution && showRemoveMemberModal[0]) {
      const payloadMembers = recordMap(
        toGroupedRecord(
          distribution.members?.filter((x) => x.memberId !== showRemoveMemberModal[0]),
          'type',
        ),
        (_, value) => value.map((x) => x.memberId),
      );
      DistributionService.updateDistribution(distribution.id, {
        members: payloadMembers,
        preferences: distribution.preferences,
        settings: distribution.settings,
      }).then((res) => {
        onDistributionUpdated(res.data);
        closeConfirmRemoveMember();
      });
    }
  }, [closeConfirmRemoveMember, distribution, onDistributionUpdated, showRemoveMemberModal]);

  useEffect(() => {
    // On leaving page, reset
    return () => setViewDistributionLogs(false);
  }, [setViewDistributionLogs]);

  return (
    <div className="my-4">
      <div className="sticky top-0 z-50 flex items-center justify-between border-b bg-white px-4 pb-2">
        <div className="font-medium">
          {t('left-tabs.distribution.heading')}{' '}
          {!!distribution && canMaintainDistribution && (
            <Tooltip text={canMaintainDistribution ? t('form:context.manage-distribution') : t('form:context.view-distribution')}>
              {(tooltip) => (
                <span {...tooltip}>
                  <CogIcon className="ml-1 h-5 w-5" onClick={() => setShowDistributionModal('manage')} />
                </span>
              )}
            </Tooltip>
          )}
        </div>
        {!!distribution && (
          <Button type={ButtonType.SECONDARY} size={ButtonSize.S} onClick={() => setViewDistributionLogs((prev) => !prev)}>
            <div className="flex items-center gap-2">
              {viewDistributionLogs ? t('left-tabs.distribution.hide-logs-button') : t('left-tabs.distribution.view-logs-button')}
            </div>
          </Button>
        )}
      </div>
      <div className="px-4 pb-2">{distribution && <DistributionMembersDropdown distribution={distribution} />}</div>
      <div className="mt-2 flex flex-col gap-2 px-4 pb-2">
        {distribution &&
          sortedUsers.map((member) => {
            const Item = ListRenderer({
              member: distribution.members.find((x) => x.memberId === member.id),
              distributionStatus: distribution.status,
              readOnly: !canMaintainDistribution || viewOnly,
              onRemove: () => setShowRemoveMemberModal([member.id, true]),
              profileImageSize: ImageSize.XS,
            });
            return (
              <div key={member.id} className="flex items-center justify-between gap-2 py-2">
                <Item id={member.id} text={`${member.firstName} ${member.lastName}`} value={member.type} />
              </div>
            );
          })}
      </div>
      {((!!distribution && sortedUsers.length === 0) || (!distribution && !canCreateDistribution)) && (
        <div className="flex h-48 items-center justify-center">{t('distribution:steps.members.empty.title')}</div>
      )}
      {canCreateDistribution && (
        <>
          <div className="flex items-center justify-center pt-6">
            <DistributionStartMessage onStartClick={() => setShowDistributionModal('create')} size="small" />
          </div>
          <DistributionWizard
            open={showDistributionModal === 'create'}
            onClose={() => setShowDistributionModal(false)}
            startingStep="members"
            clientFormId={clientForm.id}
            clientFormVersion={clientForm.majorVersion}
            clientFormSubtitle={
              StringUtils.makePrefixWithNumber(clientForm.prefix, clientForm.documentNumber, clientForm.templateModuleTranslations) +
              '-' +
              clientForm.subtitle
            }
          />
        </>
      )}
      {canViewDistribution && (
        <DistributionManageModal
          open={showDistributionModal === 'manage'}
          onClose={() => setShowDistributionModal(false)}
          distribution={(clientForm as DocumentResponse).distribution}
          clientFormSubtitle={
            StringUtils.makePrefixWithNumber(clientForm.prefix, clientForm.documentNumber, clientForm.templateModuleTranslations) +
            '-' +
            clientForm.subtitle
          }
        />
      )}
      <ModalContext.Provider value={{ open: showRemoveMemberModal[1], modalWidth: 'w-2/5', onClose: closeConfirmRemoveMember }}>
        <ConfirmationModal
          onConfirm={removeMember}
          onCancel={closeConfirmRemoveMember}
          title={t('distribution:delete-member.title')}
          description={t('distribution:delete-member.description')}
          confirmText={t('distribution:delete-member.confirm')}
        />
      </ModalContext.Provider>
    </div>
  );
};

export default CondensedDistribution;
