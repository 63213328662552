import { useCallback } from 'react';
import { useCurrentUser } from '../../global-state/Auth';
import { useCurrentClient } from '../../global-state/Clients';

const useOnlyHasPermission = () => {
  const currentUser = useCurrentUser((x) => x.value);
  const currentClient = useCurrentClient((x) => x.value);

  return useCallback(
    (permission: string) => {
      const userRoles = currentClient?.id ? currentUser?.roles[currentClient.id] || [] : currentUser?.roles[currentUser.defaultAccountId || ''] || [];

      return userRoles.length <= 1 && userRoles[0] === permission;
    },
    [currentClient?.id, currentUser?.defaultAccountId, currentUser?.roles],
  );
};

export default useOnlyHasPermission;
