import { useCallback } from 'react';
import { ClientFormUserResponse, ClientFormUser } from '../models/ClientFormUser';
import ClientFormService from '../services/ClientFormService';
import { useCurrentFormUsers, useFormSectionUsers } from '../global-state/Forms';

const useFormUsers = () => {
  const setFormUsers = useCurrentFormUsers((x) => x.setValue);
  const setFormSectionUsers = useFormSectionUsers((x) => x.setValue);

  return useCallback(
    (clientFormId: string) => {
      ClientFormService.getFormUsers(clientFormId).then((res) => {
        const users = res.data.map((el: ClientFormUserResponse) => {
          const { user, ...rest } = el;
          return { ...rest, ...user };
        });
        const formUsers: ClientFormUser[] = [];
        const formSectionUsers: ClientFormUser[] = [];
        for (const user of users) {
          (user.formSectionId ? formSectionUsers : formUsers).push(user);
        }
        setFormUsers(formUsers);
        setFormSectionUsers(formSectionUsers);
      });
    },
    [setFormSectionUsers, setFormUsers],
  );
};

export default useFormUsers;
