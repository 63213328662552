import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { ClientForm } from '../../models/ClientForm';
import { FormType } from '../../models/FormTypes';
import ClientFormService from '../../services/ClientFormService';
import ModuleService from '../../services/ModuleService';
import FormUtils from '../../utils/FormUtils';
import LanguageUtils from '../../utils/LanguageUtils';
import StaticBreadCrumb, { BreadCrumbItem } from '../shared/breadcumb/StaticBreadCrumb';
import FormLocationInfo from './action-types/FormLocationInfo';
import { useCurrentClient } from '../../global-state/Clients';

type FormBreadCrumbProps = {
  clientForm: ClientForm;
};

const FormBreadCrumb: FC<FormBreadCrumbProps> = (props) => {
  const { clientForm } = props;
  const client = useCurrentClient((x) => x.value);
  const [formHierarchy, setFormHierarchy] = useState<BreadCrumbItem[]>([]);
  const [moduleInfo, setModuleInfo] = useState<BreadCrumbItem>({ name: '', path: '' });
  const { formId } = useParams<{ formId: string }>();
  const { t } = useTranslation(['form', 'module-list', 'common']);

  const formLocationInfo: FormLocationInfo = useMemo(
    () => ({
      moduleId: clientForm?.clientModuleId,
      sectionId: clientForm?.clientModuleSectionId,
      formId: formId as string,
      isAsset: clientForm?.form.type === FormType.Asset,
    }),
    [clientForm?.clientModuleId, clientForm?.clientModuleSectionId, clientForm?.form.type, formId],
  );

  const breadCrumbs = useMemo(() => {
    return [{ name: t('module-list:heading'), path: `/clients/${client?.id}/modules/` }, moduleInfo, ...formHierarchy];
  }, [client?.id, formHierarchy, moduleInfo, t]);

  const createFormHieracrchy = useCallback(
    (parentId: string | null | undefined, beadCrumbItems: BreadCrumbItem[]) => {
      if (parentId) {
        ClientFormService.getFormInfo(parentId).then((res) => {
          beadCrumbItems.unshift({
            name: LanguageUtils.getTranslation('description', res.data.translations),
            path: `/clients/${client?.id}/forms/${res.data.id}`,
          });
          createFormHieracrchy(res.data.parentId, beadCrumbItems);
        });
      } else {
        setFormHierarchy(beadCrumbItems);
      }
    },
    [client?.id],
  );

  useEffect(() => {
    if (clientForm?.parentId) {
      ClientFormService.getFormInfo(clientForm?.parentId).then((res) => {
        createFormHieracrchy(res.data.parentId, [
          { name: LanguageUtils.getTranslation('description', res.data.translations), path: `/clients/${client?.id}/forms/${res.data.id}` },
        ]);
      });
    } else {
      setFormHierarchy([]);
    }
  }, [formId, clientForm?.parentId, createFormHieracrchy, client?.id]);

  useEffect(() => {
    if (formLocationInfo.moduleId) {
      ModuleService.getModuleInfo(formLocationInfo.moduleId).then((res) => {
        setModuleInfo({
          name: LanguageUtils.getTranslation('name', res.data.translations),
          path: `/clients/${client?.id}/modules/${formLocationInfo.moduleId}`,
        });
      });
    }
  }, [formId, client?.id, formLocationInfo.moduleId]);
  return <StaticBreadCrumb breadCrumbs={breadCrumbs} currentStepName={FormUtils.getFormTitle(clientForm)} />;
};

export default FormBreadCrumb;
