import { FC, Fragment, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ClientForm } from '../../../models/ClientForm';
import LanguageUtils from '../../../utils/LanguageUtils';
import DateUtils from '../../../utils/DateUtils';
import { ClientFormUserRole, ClientFormUserRoleKeys, ClientFormUserRoleValues } from '../../../models/ClientFormUserRoles';
import { FormType } from '../../../models/FormTypes';
import { DocumentResponse } from '../../../models/Document';
import { FileUtils } from '../../../utils/FileUtils';
import { ClientModule } from '../../../models/ClientModule';
import ModuleService from '../../../services/ModuleService';
import { AccessTypeKeys } from '../../../models/Access';
import StringUtils from '../../../utils/StringUtils';
import { PeriodicReviewUtils } from '../../../utils/PeriodicReviewUtils';
import PeriodicReviewRecurrence from '../../shared/periodic-review/PeriodicReviewRecurrence';
import InfoIcon from '../../shared/icon/InfoIcon';
import Tooltip from '../../shared/Tooltip';
import useStatusText from '../../../hooks/useStatusText';
import { ClientFormUser } from '../../../models/ClientFormUser';
import useHistoriesForPerformingAction from '../../../hooks/useHistoriesForPerformingAction';
import { PerformedAction } from '../../../models/ClientFormVersionHistory';
import { useCurrentFormUsers, useFormSectionUsers } from '../../../global-state/Forms';

const Heading: FC<{ title: string }> = ({ title }) => <div className="border-b px-4 pb-2 font-medium">{title}</div>;
const Property: FC<{ title: string | ReactElement; value: number | string | ReactElement }> = ({ title, value }) => (
  <div className="text-dpm-14 my-4 flex flex-col items-start justify-between gap-2 px-4">
    <div className="font-medium">{title}</div>
    <div
      className="text-left"
      style={{
        wordBreak: 'break-word',
        overflowWrap: 'break-word',
      }}
    >
      {value}
    </div>
  </div>
);
const WrappedInfo: FC<{ text: string }> = ({ text }) => (
  <Tooltip text={text}>
    {(tooltip) => (
      <span {...tooltip}>
        <InfoIcon className="h-4 w-4" />
      </span>
    )}
  </Tooltip>
);

const Info = (text: string) => <WrappedInfo text={text} />;
const ModuleName: FC<{ clientModuleId: string }> = ({ clientModuleId }) => {
  const [module, setModule] = useState<ClientModule | null>(null);
  useEffect(() => {
    ModuleService.getModule(clientModuleId).then((res) => {
      setModule(res.data);
    });
  }, [clientModuleId]);

  const moduleName = useMemo(() => {
    if (!module) {
      return '-';
    }
    return LanguageUtils.getTranslation('name', module.templateModule.translations);
  }, [module]);

  return moduleName;
};

type Props = {
  clientForm: DocumentResponse | ClientForm;
};

const CondensedActivityDetails: FC<Props> = (props) => {
  const { clientForm } = props;
  const { t } = useTranslation(['form', 'common', 'documents']);
  const formUsers = useCurrentFormUsers((x) => x.value);
  const formSectionUsers = useFormSectionUsers((x) => x.value);
  const allUsers = useMemo(() => formUsers.concat(formSectionUsers), [formSectionUsers, formUsers]);
  const statusText = useStatusText(clientForm);

  const applicableUserRoles = useMemo(() => {
    const result: ClientFormUserRoleValues[] = [ClientFormUserRole.Owner];

    if (clientForm.form.requiresApproval) result.push(ClientFormUserRole.Approver);
    if (clientForm.form.requiresValidation) result.push(ClientFormUserRole.Validator);

    return result.concat([ClientFormUserRole.Contributor, ClientFormUserRole.Viewer]);
  }, [clientForm.form.requiresApproval, clientForm.form.requiresValidation]);

  const usersForRole = useCallback(
    (role: ClientFormUserRoleValues) => {
      return allUsers
        .filter((x) => x.role === role)
        .sort((a, b) => {
          // First, sort by `requiresAction` (true first),
          // then, sort by `formSectionId` (null first),
          // then, sort by `sortOrder` (ascending order, if it exists),
          // then, sort by `fullName` (alphabetical order).
          if (a.requiresAction !== b.requiresAction) {
            return a.requiresAction ? -1 : 1;
          }
          if (a.formSectionId === null && b.formSectionId !== null) {
            return -1;
          }
          if (a.formSectionId !== null && b.formSectionId === null) {
            return 1;
          }
          if (typeof a.sortOrder === 'number' && typeof b.sortOrder === 'number') {
            return a.sortOrder - b.sortOrder;
          }
          return (a.fullName ?? '').localeCompare(b.fullName ?? '');
        });
    },
    [allUsers],
  );

  return (
    <div className="mt-4">
      <Heading title={t('left-tabs.details.headings.details')} />

      <Property
        title={t('left-tabs.details.properties.title')}
        value={clientForm.subtitle || LanguageUtils.getTranslation('title', clientForm.form.translations)}
      />
      <Property title={t('form:left-tabs.details.properties.version')} value={`v${clientForm.majorVersion}`} />
      {clientForm.type === FormType.Document && (
        <Property title={t('form:left-tabs.details.properties.class')} value={<ModuleName clientModuleId={clientForm.clientModuleId} />} />
      )}
      {!clientForm.form.isSystem && (
        <Property title={t('form:left-tabs.details.properties.type')} value={LanguageUtils.getTranslation('title', clientForm.form.translations)} />
      )}
      {clientForm.type === FormType.Document && (
        <Property
          title={t('form:left-tabs.details.properties.number')}
          value={StringUtils.addLeadingZeros((clientForm as DocumentResponse).documentNumber)}
        />
      )}
      <Property title={t('form:left-tabs.details.properties.status')} value={statusText} />
      <Property title={t('form:left-tabs.details.properties.last-modified')} value={DateUtils.formatDate(new Date(clientForm.modifiedUtc))} />
      {clientForm.type === FormType.Document && clientForm.form.isSystem && (
        <Property title={t('form:left-tabs.details.properties.size')} value={FileUtils.formatSize((clientForm as DocumentResponse).fileSize)} />
      )}
      {clientForm.type === FormType.Document && clientForm.form.isSystem && (
        <Property
          title={t('form:left-tabs.details.properties.format')}
          value={FileUtils.getFileExtensionFromMimeType((clientForm as DocumentResponse).fileFormat)}
        />
      )}
      {clientForm.type === FormType.Document && (
        <>
          <Heading title={t('left-tabs.details.properties.access')} />
          <div className="text-dpm-14 my-4 px-4">
            {t(AccessTypeKeys[(clientForm as DocumentResponse).accessType], { client: clientForm?.clientName })}
          </div>
        </>
      )}
      <Heading title={t('left-tabs.details.headings.users')} />
      {applicableUserRoles.map((x) => (
        <Users key={x} clientForm={clientForm} role={x} users={usersForRole(x)} />
      ))}
      {clientForm.periodicReviewConfig && (
        <>
          <Heading title={t('form:info-modal.details.sections.periodic-review.title')} />
          <Property
            title={
              <Trans
                t={t}
                i18nKey="form:info-modal.details.sections.periodic-review.fields.due-date"
                components={{
                  InfoIcon: Info(t('form:info-modal.details.sections.periodic-review.fields.due-date-help')),
                }}
              />
            }
            value={
              clientForm.periodicReviewDueDateUtc
                ? DateUtils.formatDate(new Date(clientForm.periodicReviewDueDateUtc))
                : t('form:info-modal.details.sections.periodic-review.fields.due-date-placeholder')
            }
          />
          <Property
            title={t('form:info-modal.details.sections.periodic-review.fields.recurrence')}
            value={<PeriodicReviewRecurrence source={clientForm} />}
          />
          <Property
            title={
              <Trans
                t={t}
                i18nKey="form:info-modal.details.sections.periodic-review.fields.advanced-notice"
                components={{
                  InfoIcon: Info(t('form:info-modal.details.sections.periodic-review.fields.advanced-notice-help')),
                }}
              />
            }
            value={PeriodicReviewUtils.getAdvancedNoticeText(clientForm.periodicReviewConfig, t)}
          />
          <Property
            title={
              <Trans
                t={t}
                i18nKey="form:info-modal.details.sections.periodic-review.fields.completed-count"
                components={{
                  InfoIcon: Info(
                    t('form:info-modal.details.sections.periodic-review.fields.completed-count-help', {
                      Count: clientForm.periodicReviewCount,
                    }),
                  ),
                }}
              />
            }
            value={clientForm.periodicReviewCount}
          />
          <Property title={t('form:info-modal.details.sections.periodic-review.fields.goal')} value={clientForm.periodicReviewConfig.goal || '-'} />
          <Property
            title={t('form:info-modal.details.sections.periodic-review.fields.repercussions')}
            value={clientForm.periodicReviewConfig.repercussions || '-'}
          />
        </>
      )}
    </div>
  );
};

const Users: FC<{ role: ClientFormUserRoleValues; users: ClientFormUser[]; clientForm: ClientForm }> = (props) => {
  const { clientForm, role, users } = props;
  const userHistories = useHistoriesForPerformingAction(clientForm.id);
  const { t } = useTranslation(['form', 'common']);

  const stepNames = useMemo(() => {
    const result = new Map<string, string>();
    for (const section of clientForm.form.sections) {
      result.set(section.id, LanguageUtils.getTranslation('title', section.translations));
    }
    return result;
  }, [clientForm.form.sections]);

  const hasSortOrder = useMemo(() => users.some((x) => x.sortOrder !== null), [users]);

  const actionedUsers = useMemo(() => {
    return users.map((user) => ({
      ...user,
      actionPerformed:
        userHistories.find((history) => history.userId === user.id && history.role === role)?.performedAction ?? PerformedAction.Unknown,
    }));
  }, [role, userHistories, users]);

  const displayText = useMemo(() => {
    let currentGroup = '';
    const result = [
      <div
        key="title"
        className={`mt-3 font-medium text-black ${role !== ClientFormUserRole.Owner ? (role === ClientFormUserRole.Viewer || role === ClientFormUserRole.Contributor ? '' : '-mb-2') : ''}`}
      >
        {t(ClientFormUserRoleKeys[role])}
      </div>,
    ];

    if (actionedUsers.length === 0) {
      return result.concat(<Fragment key="empty-state">-</Fragment>);
    }

    for (const user of actionedUsers) {
      const hasStatusCol =
        ([ClientFormUserRole.Owner, ClientFormUserRole.Approver, ClientFormUserRole.Validator] as ClientFormUserRoleValues[]).includes(role) &&
        (role === ClientFormUserRole.Owner
          ? user.requiresAction || [PerformedAction.ClientFormApprove, PerformedAction.ClientFormValidate].includes(user.actionPerformed)
          : true);
      const userGroup = hasStatusCol ? (user.requiresAction ? 'required' : 'optional') : '';

      if (userGroup && currentGroup !== userGroup) {
        currentGroup = userGroup;
        result.push(
          <div key={`${userGroup}-heading`} className="text-gray-1 mt-2 flex items-center justify-between gap-3">
            <div className="w-3/4 font-medium">
              {hasSortOrder && user.requiresAction && <span className="inline-block w-5">#</span>}
              {t(`left-tabs.details.headings.user-headings.${userGroup}`)}
            </div>
            <div className="w-1/4 flex-shrink-0 font-medium">{t('left-tabs.details.headings.user-headings.status')}</div>
          </div>,
        );
      }

      result.push(
        <div
          key={`${role}-${user.id}`}
          className={`flex items-center justify-between gap-3 ${([ClientFormUserRole.Approver, ClientFormUserRole.Validator] as ClientFormUserRoleValues[]).includes(role) && !hasStatusCol ? 'mt-2' : ''}`}
        >
          <Tooltip text={user.fullName} truncatedTextMode>
            {(tooltip) => (
              <div {...tooltip} className={`${hasStatusCol ? 'w-3/4' : ''} truncate`}>
                {hasSortOrder && user.requiresAction ? (
                  <span className="inline-block w-5">
                    {typeof user.sortOrder === 'number' && !user.formSectionId ? `${user.sortOrder + 1}. ` : '-'}
                  </span>
                ) : null}
                {user.fullName}{' '}
                {user.formSectionId && (
                  <Tooltip
                    text={t(
                      `left-tabs.details.headings.user-headings.step-user-${role === ClientFormUserRole.Approver ? 'approver' : 'reviewer'}${user.requiresAction ? '-required' : ''}`,
                      { step: stepNames.get(user.formSectionId) },
                    )}
                  >
                    {(tooltip) => (
                      <span {...tooltip}>
                        <InfoIcon className="h-4 w-4" />
                      </span>
                    )}
                  </Tooltip>
                )}
              </div>
            )}
          </Tooltip>
          {hasStatusCol && (
            <div
              className={`w-1/4 flex-shrink-0 ${
                [PerformedAction.ClientFormApprove, PerformedAction.ClientFormValidate].includes(user.actionPerformed ?? PerformedAction.Unknown)
                  ? 'text-semantic-1'
                  : 'text-gray-1'
              }`}
            >
              {t(
                `left-tabs.details.headings.user-headings.${user.actionPerformed === PerformedAction.ClientFormApprove ? 'approved' : user.actionPerformed === PerformedAction.ClientFormValidate ? 'reviewed' : user.requiresAction ? 'pending' : 'optional'}`,
              )}
            </div>
          )}
        </div>,
      );
    }

    return result;
  }, [actionedUsers, hasSortOrder, role, stepNames, t]);

  return <div className="text-dpm-14 my-2 px-4">{displayText}</div>;
};

export default CondensedActivityDetails;
