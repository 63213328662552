import StandardModal from '../../shared/modal/variants/StandardModal';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useState } from 'react';
import ClientTemplateModuleService from '../../../services/ClientTemplateModuleService';
import { FormType } from '../../../models/FormTypes';
import { ClientFormDefault } from '../../../models/ClientFormDefaults';
import { SearchInput } from '../../shared/form-control/SearchInput';
import { InputStyle } from '../../shared/form-control/Input';
import DocumentSpaceIcon from '../../shared/icon/DocumentSpaceIcon';
import { Heading, HeadingSize } from '../../shared/text/Heading';
import Loader from '../../shared/Loader';
import LanguageUtils from '../../../utils/LanguageUtils';
import Tooltip from '../../shared/Tooltip';
import { useReplaceDocumentWizard } from '../../../contexts/ReplaceDocumentContext';
import { useCurrentClient } from '../../../global-state/Clients';

const PickTemplateStep = () => {
  const { nextStep, prevStep, setDocument, clientModuleId } = useReplaceDocumentWizard();
  const { t } = useTranslation(['documents', 'organisation', 'common', 'module']);
  const currentClient = useCurrentClient((x) => x.value);
  const [documentTemplates, setDocumentTemplates] = useState<ClientFormDefault[]>([]);
  const [filteredDocumentTemplates, setFilteredDocumentTemplates] = useState<ClientFormDefault[]>([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (currentClient) {
      setIsLoading(true);
      const service = new ClientTemplateModuleService(currentClient?.id);
      service
        .getAllTemplateFormDefaults({ pageNumber: 1, pageSize: 999, clientModuleId: clientModuleId, types: [FormType.Document] })
        .then((res) => setDocumentTemplates(res.data))
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [clientModuleId, currentClient]);

  useEffect(() => {
    setFilteredDocumentTemplates(
      documentTemplates.filter((x) =>
        LanguageUtils.getTranslation('title', x.templateForm.translations ?? {})
          .toLowerCase()
          .includes(searchQuery.toLowerCase()),
      ),
    );
  }, [documentTemplates, searchQuery]);

  const selectTemplate = useCallback(
    (templateId: string) => {
      setDocument && setDocument((prev) => ({ ...prev, templateFormId: templateId }));
      nextStep && nextStep('detailStep');
    },
    [nextStep, setDocument],
  );

  return (
    <StandardModal title={t('create-doc-wizard.title')} cancelButtonTitle={t('manage-wizard.buttons.back')} onCancelClick={prevStep}>
      <div className="-mx-3 flex-grow">
        <div className="flex">
          <div className="w-full pl-4">
            <div className="flex justify-between gap-2">
              <div className="text-dpm-16 font-medium">{t('create-doc-wizard.templates.select')}</div>
              <div className="w-80">
                <SearchInput
                  data-cy="doc-class-search"
                  placeholder={t('common:list.filter.search')}
                  style={InputStyle.MINIMAL}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  value={searchQuery}
                />
              </div>
            </div>
            <div className="relative mt-2 h-[55vh] overflow-auto">
              {isLoading && <Loader centered size={16} />}
              {filteredDocumentTemplates.map((documentTemplate) => {
                const templateName = LanguageUtils.getTranslation('title', documentTemplate.templateForm.translations || {});
                return (
                  <div
                    key={documentTemplate.templateForm.id}
                    onClick={() =>
                      documentTemplate.templateForm.id && documentTemplate.clientModuleId && selectTemplate(documentTemplate.templateForm.id)
                    }
                    className="hover:bg-accent-light-mid border-b-gray-6 flex cursor-pointer items-start gap-4 border-b px-4 py-4"
                  >
                    <Tooltip text={templateName} truncatedTextMode>
                      {(tooltip) => (
                        <div {...tooltip} className="truncate">
                          <span className="font-medium">{templateName}</span>
                          <span className="text-accent-1 text-dpm-12 ml-2">
                            {LanguageUtils.getTranslation('name', documentTemplate.templateModuleTranslations || {})}
                          </span>
                        </div>
                      )}
                    </Tooltip>
                  </div>
                );
              })}
              {filteredDocumentTemplates.length === 0 && !isLoading && (
                <div className="flex h-full w-full justify-center text-center">
                  <div className="flex h-full w-4/5 flex-col items-center justify-center">
                    <DocumentSpaceIcon className="bg-background-1 mt-5 h-16 w-16 rounded-full p-4" />
                    <Heading size={HeadingSize.H5} className="mt-4">
                      {t('create-doc-wizard.templates.empty.title')}
                    </Heading>
                    <p className="mb-8">{t('create-doc-wizard.templates.empty.description')}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </StandardModal>
  );
};

export default PickTemplateStep;
