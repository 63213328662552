import { FC, ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ToastType, useToasts } from '../../contexts/ToastContext';
import { ClientFormUserRole } from '../../models/ClientFormUserRoles';
import {
  canCurrentUserApproveForm,
  canCurrentUserReopenForm,
  canCurrentUserSubmitForm,
  canCurrentUserValidateForm,
  determineReviewApprovalStatus,
} from '../../utils/FormPermissionUtils';
import { ClientFormStatus } from '../../models/ClientFormStatus';
import { ClientFormUser } from '../../models/ClientFormUser';
import ClientFormService from '../../services/ClientFormService';
import Button, { ButtonSize, ButtonType } from '../shared/form-control/Button';
import MultiTextField from '../shared/form-control/MultiTextField';
import { useTranslation } from 'react-i18next';
import { ClientForm, ClientFormSectionInfo, SignatureDetails } from '../../models/ClientForm';
import PermissionsModal from '../ownership/PermissionsModal';
import { FormSection } from '../../models/Form';
import { ClientFormSectionStatus } from '../../models/ClientFormSectionStatus';
import { EventSystem } from '../../events/EventSystem';
import { FormValidEvent } from '../../events/FormValidEvent';
import { ModalContext } from '../../contexts/ModalContext';
import StandardModal from '../shared/modal/variants/StandardModal';
import ContextMenu from '../shared/ContextMenu';
import PeriodicReviewService from '../../services/PeriodicReviewService';
import { useNavigate, useParams } from 'react-router';
import { useCurrentRoute } from '../../hooks/useCurrentRoute';
import { PeriodicReviewStatus } from '../../models/PeriodicReview';
import useFormTypeText from '../../hooks/useFormTypeText';
import ValidateModal from './ValidateModal';
import ApproveModal from './ApproveModal';
import { FormType, FormTypeKeys } from '../../models/FormTypes';
import { useDownloadPdf } from '../../hooks/useDownloadPdf';
import FormUtils from '../../utils/FormUtils';
import { DocumentResponse } from '../../models/Document';
import DueDatePicker from '../shared/DueDatePicker';
import { EffectiveDateModalContent } from '../shared/EffectiveDatePicker';
import useHistoriesForPerformingAction from '../../hooks/useHistoriesForPerformingAction';
import useFormUsers from '../../hooks/useFormUsers';
import { useCurrentUser } from '../../global-state/Auth';
import { useCurrentClient } from '../../global-state/Clients';

type FormSubmitProps = {
  clientForm: ClientForm;
  formStatus: ClientFormStatus;
  formUsers: ClientFormUser[];
  leftBorder?: boolean;
  allSteps: FormSection[];
  allStepInfos: ClientFormSectionInfo[];
  effectiveDateErrorState: { type: 'approval' | 'validation' | 'submit'; text: ReactElement } | null;
  onStatusChange: (formStatus: ClientFormStatus) => void;
  onUserAdded: () => void;
};

const FormSubmit: FC<FormSubmitProps> = (props) => {
  const { clientForm, onStatusChange, formStatus, formUsers, onUserAdded, leftBorder, allSteps, allStepInfos, effectiveDateErrorState } = props;
  const currentUser = useCurrentUser((x) => x.value);
  const params = useParams<{ formId: string }>();
  const [isFormValid, setIsFormValid] = useState(false);
  const [currentFormUser, setCurrentFormUser] = useState<ClientFormUser | null>(null);
  const refetchUsers = useFormUsers();
  const [showMissingValidatorsModal, setShowMissingValidatorsModal] = useState(false);

  const [showConfirmSubmitModal, setShowConfirmSubmitModal] = useState(false);
  const [showConfirmValidateModal, setShowConfirmValidateModal] = useState(false);
  const [showConfirmApproveModal, setShowConfirmApproveModal] = useState(false);
  const [showConfirmRejectFormModal, setShowConfirmRejectFormModal] = useState(false);
  const [showConfirmReopenFormModal, setShowConfirmReopenFormModal] = useState(false);

  const [submitReason, setSubmitReason] = useState('');
  const [validateReason, setValidateReason] = useState('');
  const [rejectReason, setRejectReason] = useState('');
  const [reopenReason, setReopenReason] = useState('');
  const [reopenDueDate, setReopenDueDate] = useState<Date | null>(null);
  const [reopenEffectiveDate, setReopenEffectiveDate] = useState<Date | null>();
  const [reopenAction, setReopenAction] = useState<'reopen' | 'periodic-review'>('reopen');
  const [approveReason, setApproveReason] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const toasts = useToasts();
  const toastsRef = useRef(toasts);
  const { t } = useTranslation(['common']);
  const client = useCurrentClient((x) => x.value);
  const navigate = useNavigate();
  const currentRoute = useCurrentRoute();
  const formTypeText = useFormTypeText(clientForm.type, t);
  const { triggerPdfDownload, pdfDownloadModal } = useDownloadPdf('pdf');

  const userHistories = useHistoriesForPerformingAction(params.formId);

  useEffect(() => {
    const handler = (e: FormValidEvent) => {
      if (e.clientFormId === clientForm.id) {
        setIsFormValid(e.valid);
      }
    };

    EventSystem.listen('form-valid', handler);

    return () => EventSystem.stopListening('form-valid', handler);
  }, [clientForm.allSectionsValid, clientForm.id]);

  useEffect(() => {
    if (currentUser) {
      const formUser = ClientFormService.getCurrentFormUser(formUsers, currentUser);
      setCurrentFormUser(formUser);
    }
  }, [currentUser, formUsers]);

  const isShared = useMemo(() => {
    return clientForm.clientId !== client?.id;
  }, [client?.id, clientForm.clientId]);

  const setCurrentUserStatusUtc = (date: string | null) => {
    setCurrentFormUser((prevUser) => {
      if (prevUser) {
        return { ...prevUser, statusUtc: date };
      }
      return prevUser;
    });
  };

  const canStartReview = useMemo(
    () => clientForm.periodicReviewStatus === PeriodicReviewStatus.UpcomingReview && clientForm.status === ClientFormStatus.Completed,
    [clientForm.periodicReviewStatus, clientForm.status],
  );

  const canSubmitForm = useMemo(
    () => clientForm.form.isSystem || (isFormValid && clientForm.allSectionsValid),
    [clientForm.allSectionsValid, clientForm.form.isSystem, isFormValid],
  );

  const missingValidators = useMemo(() => {
    return !formUsers.find((user) => user.role === ClientFormUserRole.Validator);
  }, [formUsers]);

  const missingApprovers = useMemo(() => {
    return !formUsers.find((user) => user.role === ClientFormUserRole.Approver);
  }, [formUsers]);

  const requiresApproval = clientForm.form.requiresApproval;

  const requiresValidation = clientForm.form.requiresValidation;

  const missingValidatorsApproversKey = useMemo(() => {
    if (requiresValidation && missingValidators && !requiresApproval) {
      return 'missing-validators';
    } else if (requiresApproval && missingApprovers && !requiresValidation) {
      return 'missing-approvers';
    } else {
      return 'missing-validators-approvers';
    }
  }, [missingApprovers, missingValidators, requiresApproval, requiresValidation]);

  //#region Submissions

  const submitForm = () => {
    if (canSubmitForm) {
      if (
        (requiresApproval && missingApprovers) ||
        (requiresValidation && missingValidators) ||
        (requiresApproval && requiresValidation && (missingApprovers || missingValidators))
      ) {
        setShowMissingValidatorsModal(true);
      } else {
        setShowMissingValidatorsModal(false);
        setShowConfirmSubmitModal(true);
      }
    }
  };

  const doSubmitForm = () => {
    setIsSubmitting(true);
    ClientFormService.submitForm(clientForm.id, submitReason)
      .then(() => {
        setSubmitReason('');
        let description = '';
        if (requiresValidation) {
          description = t('form-header.toasts.submitted.for-review-text', { type: formTypeText });
        } else if (requiresApproval) {
          description = t('form-header.toasts.submitted.for-approval-text', { type: formTypeText });
        }
        toasts.addToast({
          title: t('form-header.toasts.submitted.title', { type: formTypeText }),
          type: ToastType.SUCCESS,
          description: description,
          expiresInMs: 5000,
        });
        onStatusChange(ClientFormStatus.SubmittedForValidation);
        setCurrentUserStatusUtc(new Date().toString());
      })
      .catch((err) => {
        toasts.addToast({
          title: t('form-header.toasts.submitted.failed', { type: formTypeText }),
          type: ToastType.ERROR,
          description: err?.data?.meta?.message,
          expiresInMs: 5000,
        });
      })
      .finally(() => {
        setShowConfirmSubmitModal(false);
        setIsSubmitting(false);
      });
  };

  const validateForm = (signatureDetails?: SignatureDetails) => {
    if (canSubmitForm) {
      setIsSubmitting(true);
      return ClientFormService.validateForm(clientForm.id, validateReason, signatureDetails)
        .then(() => {
          if (!formUsers.find((user) => user.id === currentUser?.id && user.role === ClientFormUserRole.Validator)) {
            refetchUsers(clientForm.id);
          }
          setValidateReason('');
          if (clientForm.form.requiresValidationSignature) {
            toasts.addToast({
              title: t('form-header.toasts.validated-signed.title'),
              type: ToastType.SUCCESS,
              description: t(`form-header.toasts.validated-signed.text${clientForm.form.requiresApproval ? '-approval' : ''}`, {
                title: clientForm.subtitle,
              }),
              expiresInMs: 5000,
              slots: {
                button: (
                  <Button
                    type={ButtonType.TERTIARY}
                    onClick={() =>
                      triggerPdfDownload(
                        clientForm.id,
                        clientForm.type === FormType.Document
                          ? FormUtils.getDocumentTitle(clientForm as DocumentResponse)
                          : FormUtils.getFormTitle(clientForm),
                      )
                    }
                  >
                    {t('form-header.toasts.download')}
                  </Button>
                ),
              },
            });
          } else {
            toasts.addToast({
              title: t('form-header.toasts.validated.title', { type: formTypeText }),
              type: ToastType.SUCCESS,
              description: t('form-header.toasts.validated.text', { type: formTypeText }),
              expiresInMs: 5000,
              slots: {
                button: (
                  <Button
                    type={ButtonType.TERTIARY}
                    onClick={() =>
                      triggerPdfDownload(
                        clientForm.id,
                        clientForm.type === FormType.Document
                          ? FormUtils.getDocumentTitle(clientForm as DocumentResponse)
                          : FormUtils.getFormTitle(clientForm),
                      )
                    }
                  >
                    {t('form-header.toasts.download')}
                  </Button>
                ),
              },
            });
          }
          onStatusChange(ClientFormStatus.SubmittedForApproval);
          setCurrentUserStatusUtc(new Date().toString());
        })
        .catch((reason) => {
          toasts.addToast({
            title: t('form-header.toasts.validated.failed', { type: formTypeText }),
            type: ToastType.ERROR,
            description: reason?.data?.meta?.message,
            expiresInMs: 5000,
          });
        })
        .finally(() => {
          setShowConfirmValidateModal(false);
          setIsSubmitting(false);
        });
    }

    return Promise.reject();
  };

  const approveForm = (signatureDetails?: SignatureDetails) => {
    if (canSubmitForm) {
      setIsSubmitting(true);
      return ClientFormService.approveForm(clientForm.id, approveReason, signatureDetails)
        .then(() => {
          if (!formUsers.find((user) => user.id === currentUser?.id && user.role === ClientFormUserRole.Approver)) {
            refetchUsers(clientForm.id);
          }
          setApproveReason('');
          if (clientForm.form.requiresApprovalSignature) {
            toasts.addToast({
              title: t('form-header.toasts.approved-signed.title', { type: formTypeText }),
              type: ToastType.SUCCESS,
              description: t('form-header.toasts.approved-signed.text', { title: clientForm.subtitle }),
              expiresInMs: 5000,
              slots: {
                button: (
                  <Button
                    type={ButtonType.TERTIARY}
                    onClick={() =>
                      triggerPdfDownload(
                        clientForm.id,
                        clientForm.type === FormType.Document
                          ? FormUtils.getDocumentTitle(clientForm as DocumentResponse)
                          : FormUtils.getFormTitle(clientForm),
                      )
                    }
                  >
                    {t('form-header.toasts.download')}
                  </Button>
                ),
              },
            });
          } else {
            toasts.addToast({
              title: t('form-header.toasts.approved.title', { type: formTypeText }),
              type: ToastType.SUCCESS,
              description: t('form-header.toasts.approved.text', { type: formTypeText }),
              expiresInMs: 5000,
              slots: {
                button: (
                  <Button
                    type={ButtonType.TERTIARY}
                    onClick={() =>
                      triggerPdfDownload(
                        clientForm.id,
                        clientForm.type === FormType.Document
                          ? FormUtils.getDocumentTitle(clientForm as DocumentResponse)
                          : FormUtils.getFormTitle(clientForm),
                      )
                    }
                  >
                    {t('form-header.toasts.download')}
                  </Button>
                ),
              },
            });
          }
          onStatusChange(ClientFormStatus.Completed);
          setCurrentUserStatusUtc(new Date().toString());
        })
        .catch((reason) => {
          toasts.addToast({
            title: t('form-header.toasts.approved.failed', { type: formTypeText }),
            type: ToastType.ERROR,
            description: reason?.data?.meta?.message,
            expiresInMs: 5000,
          });
        })
        .finally(() => {
          setShowConfirmApproveModal(false);
          setIsSubmitting(false);
        });
    }

    return Promise.reject();
  };

  const rejectForm = () => {
    if (rejectReason) {
      setIsSubmitting(true);
      ClientFormService.rejectForm(clientForm.id, rejectReason)
        .then(() => {
          setRejectReason('');
          toasts.addToast({
            title: t('form-header.toasts.rejected.title', { type: formTypeText }),
            type: ToastType.SUCCESS,
            description: t('form-header.toasts.rejected.text', { type: formTypeText }),
            expiresInMs: 5000,
          });
          onStatusChange(ClientFormStatus.InProgress);
          setCurrentUserStatusUtc(null);
        })
        .catch((reason) => {
          toasts.addToast({
            title: t('form-header.toasts.rejected.failed', { type: formTypeText }),
            type: ToastType.ERROR,
            description: reason?.data?.meta?.message,
            expiresInMs: 5000,
          });
        })
        .finally(() => {
          setShowConfirmRejectFormModal(false);
          setIsSubmitting(false);
        });
    }
  };

  const reOpenForm = () => {
    if (reopenReason) {
      setIsSubmitting(true);
      ClientFormService.reopenForm(clientForm.id, reopenReason, reopenDueDate, reopenEffectiveDate)
        .then((res) => {
          setReopenReason('');
          setReopenDueDate(null);
          setReopenEffectiveDate(null);
          toasts.addToast({
            title: t('form-header.toasts.reopen.title'),
            type: ToastType.SUCCESS,
            description: t('form-header.toasts.reopen.text', { version: res.data }),
            expiresInMs: 5000,
          });
          onStatusChange(ClientFormStatus.InProgress);
          setCurrentUserStatusUtc(null);
          navigate(`/clients/${clientForm?.clientId}/forms/${clientForm?.id}?edit`);
        })
        .catch((reason) => {
          toasts.addToast({
            title: t('form-header.toasts.reopen.failed', { type: formTypeText }),
            type: ToastType.ERROR,
            description: reason?.data?.meta?.message,
            expiresInMs: 5000,
          });
        })
        .finally(() => {
          setShowConfirmReopenFormModal(false);
          setIsSubmitting(false);
        });
    }
  };

  const completeForm = useCallback(() => {
    if (!canSubmitForm) {
      return;
    }
    setIsSubmitting(true);
    ClientFormService.completeForm(clientForm.id)
      .then(() => {
        toastsRef.current.addToast({
          title: t('form-header.toasts.completed.title', { type: formTypeText }),
          type: ToastType.SUCCESS,
          description: t('form-header.toasts.completed.text', { type: formTypeText }),
          expiresInMs: 5000,
        });
        if (currentRoute.id === 'form-preview') {
          onStatusChange(ClientFormStatus.Completed);
        } else {
          navigate(`/clients/${clientForm.clientId}/forms/${clientForm.id}/preview`);
        }
      })
      .catch((reason) => {
        toastsRef.current.addToast({
          title: t('form-header.toasts.completed.failed', { type: formTypeText }),
          type: ToastType.ERROR,
          description: reason?.data?.meta?.message,
          expiresInMs: 5000,
        });
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }, [canSubmitForm, clientForm.clientId, clientForm.id, currentRoute.id, formTypeText, navigate, onStatusChange, t]);

  const startReview = useCallback(() => {
    setIsSubmitting(true);
    PeriodicReviewService.start(clientForm.id, reopenDueDate, reopenEffectiveDate || null)
      .then(() => {
        onStatusChange(ClientFormStatus.InProgress);
        navigate(`/clients/${clientForm?.clientId}/forms/${clientForm?.id}?edit`);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  }, [clientForm?.clientId, clientForm.id, navigate, onStatusChange, reopenDueDate, reopenEffectiveDate]);

  //#endregion

  const userActionStatus = useMemo(() => {
    if (!currentUser) {
      return null;
    }

    let actionForStatus;
    switch (clientForm.status) {
      case ClientFormStatus.SubmittedForValidation:
        actionForStatus = ClientFormUserRole.Validator;
        break;
      case ClientFormStatus.SubmittedForApproval:
        actionForStatus = ClientFormUserRole.Approver;
        break;
      default:
        actionForStatus = null;
    }

    if (!actionForStatus) {
      return null;
    }

    const isLastAction = Boolean(
      (clientForm.form.requiresValidation && !clientForm.form.requiresApproval && clientForm.status === ClientFormStatus.SubmittedForValidation) ||
        (clientForm.form.requiresApproval && clientForm.status === ClientFormStatus.SubmittedForApproval),
    );

    return determineReviewApprovalStatus(client?.id || '', currentUser, formUsers, userHistories, actionForStatus, isLastAction);
  }, [client?.id, clientForm, formUsers, currentUser, userHistories]);

  const stillRequiresStepApproval = useMemo(() => {
    for (const section of allSteps) {
      if (section.requiresApproval && allStepInfos.find((x) => x.templateFormSectionId === section.id)?.status !== ClientFormSectionStatus.Approved) {
        return true;
      }
    }

    return false;
  }, [allStepInfos, allSteps]);

  const showSubmitButton = useMemo(() => {
    return (
      (formStatus === ClientFormStatus.NotStarted || formStatus === ClientFormStatus.InProgress) &&
      canCurrentUserSubmitForm(currentFormUser, clientForm.clientId, currentUser)
    );
  }, [clientForm.clientId, currentFormUser, currentUser, formStatus]);

  const cantPerformActionReason = useMemo(() => {
    if (!isFormValid && !clientForm.form.isSystem) {
      if (clientForm.form.requiresValidation) {
        return t('form-header.incomplete-form-validation', { type: t(FormTypeKeys[clientForm.type]).toLowerCase() });
      } else if (clientForm.form.requiresApproval) {
        return t('form-header.incomplete-form-approval', { type: t(FormTypeKeys[clientForm.type]).toLowerCase() });
      } else {
        return t('form-header.incomplete-form', { type: t(FormTypeKeys[clientForm.type]).toLowerCase() });
      }
    }

    if (stillRequiresStepApproval) {
      return t('form-header.requires-step-approval');
    }

    if (!clientForm.allSectionsValid) {
      return t('form-header.incomplete-form', { type: t(FormTypeKeys[clientForm.type]).toLowerCase() });
    }

    if (userActionStatus && userActionStatus.canPerformAction === false) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return userActionStatus?.reasonKey ? t(userActionStatus.reasonKey as any) : '';
    }

    return null;
  }, [
    clientForm.allSectionsValid,
    clientForm.form.isSystem,
    clientForm.form.requiresApproval,
    clientForm.form.requiresValidation,
    clientForm.type,
    isFormValid,
    stillRequiresStepApproval,
    t,
    userActionStatus,
  ]);

  const showValidateButton = useMemo(() => {
    return (
      formStatus === ClientFormStatus.SubmittedForValidation &&
      canCurrentUserValidateForm(currentFormUser, clientForm.clientId, currentUser) &&
      userActionStatus?.hasPerformedAction === false
    );
  }, [formStatus, currentFormUser, clientForm.clientId, currentUser, userActionStatus?.hasPerformedAction]);

  const showApproveButton = useMemo(() => {
    return (
      formStatus === ClientFormStatus.SubmittedForApproval &&
      canCurrentUserApproveForm(currentFormUser, clientForm.clientId, currentUser) &&
      userActionStatus?.hasPerformedAction === false
    );
  }, [formStatus, currentFormUser, clientForm.clientId, currentUser, userActionStatus?.hasPerformedAction]);

  const showValidateRejectButton = useMemo(() => {
    return formStatus === ClientFormStatus.SubmittedForValidation && canCurrentUserValidateForm(currentFormUser, clientForm.clientId, currentUser);
  }, [formStatus, currentFormUser, clientForm.clientId, currentUser]);

  const showApproveRejectButton = useMemo(() => {
    return formStatus === ClientFormStatus.SubmittedForApproval && canCurrentUserApproveForm(currentFormUser, clientForm.clientId, currentUser);
  }, [formStatus, currentFormUser, clientForm.clientId, currentUser]);

  const showReopenButton = useMemo(() => {
    return formStatus === ClientFormStatus.Completed && canCurrentUserReopenForm(currentFormUser, clientForm.clientId, currentUser);
  }, [clientForm.clientId, currentFormUser, currentUser, formStatus]);

  const showCompleteButton = useMemo(() => {
    return formStatus !== ClientFormStatus.Completed;
  }, [formStatus]);

  const changeUserFormRole = (users: ClientFormUser[]) => {
    return ClientFormService.addOrUpdateFormUsers(clientForm.id, users).then(() => {
      onUserAdded();
    });
  };

  return (
    <div className="flex items-center justify-end">
      {leftBorder && <div className="border-gray-5 my-1 mr-4 min-h-8 border-l-2" />}
      <div className="flex flex-col justify-center">
        <div className="flex items-center gap-2">
          {!(requiresValidation || requiresApproval) && !isShared && (
            <div>
              {showCompleteButton && (
                <Button
                  title={cantPerformActionReason || ''}
                  type={ButtonType.PRIMARY}
                  size={ButtonSize.S}
                  onClick={completeForm}
                  disabled={stillRequiresStepApproval || !canSubmitForm}
                >
                  {t('form-header.buttons.complete')}
                </Button>
              )}
            </div>
          )}
          {(requiresValidation || requiresApproval) && !isShared && (
            <>
              {showSubmitButton && (
                <>
                  <Button
                    title={
                      !cantPerformActionReason
                        ? effectiveDateErrorState?.type === 'submit'
                          ? effectiveDateErrorState.text
                          : ''
                        : cantPerformActionReason
                    }
                    data-cy="submit-form"
                    type={ButtonType.PRIMARY}
                    size={ButtonSize.S}
                    onClick={submitForm}
                    disabled={!!cantPerformActionReason || effectiveDateErrorState?.type === 'submit'}
                  >
                    {t(!requiresValidation ? 'form-header.buttons.submit-for-approval' : 'form-header.buttons.submit-for-validation')}
                  </Button>

                  <PermissionsModal
                    heading={t(`form-header.modals.${missingValidatorsApproversKey}.heading`, { type: formTypeText })}
                    description={t(`form-header.modals.${missingValidatorsApproversKey}.text`, { type: formTypeText })}
                    open={showMissingValidatorsModal}
                    onClose={() => setShowMissingValidatorsModal(false)}
                    onAddorUpdate={changeUserFormRole}
                    users={formUsers}
                    requiresApproval={!!clientForm.form.requiresApproval}
                    requiresValidation={!!clientForm.form.requiresValidation}
                  ></PermissionsModal>

                  <ModalContext.Provider
                    value={{ open: showConfirmSubmitModal, modalWidth: 'w-1/3', onClose: () => setShowConfirmSubmitModal(false) }}
                  >
                    <StandardModal
                      title={t('form-header.modals.submit.heading', { type: formTypeText })}
                      subTitle={t(requiresValidation ? 'form-header.modals.submit.validation-text' : 'form-header.modals.submit.approval-text', {
                        type: formTypeText,
                      })}
                      confirmButtonTitle={t('form-header.modals.submit.buttons.yes')}
                      cancelButtonTitle={t('form-header.modals.submit.buttons.no')}
                      onCancelClick={() => setShowConfirmSubmitModal(false)}
                      onConfirmClick={doSubmitForm}
                      confirmDisabled={isSubmitting}
                      confirmLoading={isSubmitting}
                    >
                      <MultiTextField
                        value={submitReason}
                        onChange={(e) => setSubmitReason(e.target.value)}
                        placeholder={t('form-header.modals.submit.placeholder')}
                      />
                    </StandardModal>
                  </ModalContext.Provider>
                </>
              )}
              {showValidateRejectButton && (
                <Button type={ButtonType.SECONDARY} size={ButtonSize.S} onClick={() => setShowConfirmRejectFormModal(true)}>
                  {t('form-header.buttons.validation.reject')}
                </Button>
              )}
              {showValidateButton && (
                <>
                  <Button
                    title={(effectiveDateErrorState?.type === 'validation' ? effectiveDateErrorState.text : cantPerformActionReason) || ''}
                    type={ButtonType.PRIMARY}
                    size={ButtonSize.S}
                    onClick={() => setShowConfirmValidateModal(true)}
                    disabled={!!cantPerformActionReason || effectiveDateErrorState?.type === 'validation'}
                  >
                    {t('form-header.buttons.validation.validate')}
                  </Button>

                  <ValidateModal
                    open={showConfirmValidateModal}
                    onClose={() => setShowConfirmValidateModal(false)}
                    onConfirm={validateForm}
                    reason={validateReason}
                    onReasonChange={(e) => setValidateReason(e.target.value)}
                    clientForm={clientForm}
                  />
                </>
              )}
              {showApproveRejectButton && (
                <Button type={ButtonType.SECONDARY} size={ButtonSize.S} onClick={() => setShowConfirmRejectFormModal(true)}>
                  {t('form-header.buttons.approval.reject')}
                </Button>
              )}
              {showApproveButton && (
                <>
                  <Button
                    title={(effectiveDateErrorState?.type === 'approval' ? effectiveDateErrorState.text : cantPerformActionReason) || ''}
                    type={ButtonType.PRIMARY}
                    size={ButtonSize.S}
                    onClick={() => setShowConfirmApproveModal(true)}
                    disabled={!!cantPerformActionReason || effectiveDateErrorState?.type === 'approval'}
                  >
                    {t('form-header.buttons.approval.approve')}
                  </Button>

                  <ApproveModal
                    open={showConfirmApproveModal}
                    onClose={() => setShowConfirmApproveModal(false)}
                    onConfirm={approveForm}
                    reason={approveReason}
                    onReasonChange={(e) => setApproveReason(e.target.value)}
                    clientForm={clientForm}
                  />
                </>
              )}

              <ModalContext.Provider
                value={{ open: showConfirmRejectFormModal, modalWidth: 'w-1/3', onClose: () => setShowConfirmRejectFormModal(false) }}
              >
                <StandardModal
                  title={t('form-header.modals.reject.title', { type: formTypeText })}
                  subTitle={t('form-header.modals.reject.text', { type: formTypeText })}
                  cancelButtonTitle={t('form-header.modals.reject.buttons.cancel')}
                  confirmButtonTitle={t('form-header.modals.reject.buttons.submit')}
                  onCancelClick={() => setShowConfirmRejectFormModal(false)}
                  onConfirmClick={rejectForm}
                  confirmDisabled={!rejectReason || isSubmitting}
                  confirmLoading={isSubmitting}
                >
                  <MultiTextField
                    placeholder={t('form-header.modals.reject.placeholder')}
                    value={rejectReason}
                    onChange={(event) => setRejectReason(event.target.value)}
                  />
                </StandardModal>
              </ModalContext.Provider>
            </>
          )}
          {showReopenButton && !isShared && (
            <>
              {!canStartReview && (
                <Button
                  data-cy="re-open-form"
                  size={ButtonSize.S}
                  type={ButtonType.PRIMARY}
                  onClick={() => {
                    setReopenAction('reopen');
                    setShowConfirmReopenFormModal(true);
                  }}
                >
                  {t('form-header.buttons.re-open')}
                </Button>
              )}
              {canStartReview && (
                <ContextMenu
                  items={[
                    {
                      title: t('form-header.buttons.re-open'),
                      onClick: () => {
                        setReopenAction('reopen');
                        setShowConfirmReopenFormModal(true);
                      },
                    },
                    {
                      title: t('form-header.buttons.start-review'),
                      onClick: () => {
                        setReopenAction('periodic-review');
                        setShowConfirmReopenFormModal(true);
                      },
                    },
                  ]}
                  type="button"
                  selectedButtonTitle={t('form-header.buttons.re-open')}
                  size={ButtonSize.S}
                />
              )}
              <ModalContext.Provider
                value={{ open: showConfirmReopenFormModal, modalWidth: 'w-1/3', onClose: () => setShowConfirmReopenFormModal(false) }}
              >
                <StandardModal
                  title={reopenAction === 'reopen' ? t('form-header.modals.reopen.title') : t('form-header.modals.periodic-review.title')}
                  cancelButtonTitle={t('form-header.modals.reopen.buttons.cancel')}
                  confirmButtonTitle={t('form-header.modals.reopen.buttons.submit')}
                  onCancelClick={() => setShowConfirmReopenFormModal(false)}
                  onConfirmClick={reopenAction === 'reopen' ? reOpenForm : startReview}
                  confirmDisabled={reopenAction === 'reopen' ? !reopenReason || isSubmitting : isSubmitting}
                  confirmLoading={isSubmitting}
                >
                  {reopenAction === 'reopen' && (
                    <MultiTextField
                      label={t('form-header.modals.reopen.text')}
                      placeholder={t('form-header.modals.reopen.placeholder')}
                      value={reopenReason}
                      onChange={(event) => setReopenReason(event.target.value)}
                    />
                  )}

                  <div className="text-primary-1 mt-4">
                    {t('form-header.modals.reopen.due-date')}
                    <DueDatePicker
                      clientFormStatus={ClientFormStatus.NotStarted}
                      disabled={false}
                      value={reopenDueDate ? new Date(reopenDueDate) : null}
                      onChange={(value) => setReopenDueDate(value)}
                    />
                  </div>

                  {clientForm.type === FormType.Document && (
                    <div className="mt-4">
                      <EffectiveDateModalContent
                        heading={true}
                        value={reopenEffectiveDate ? new Date(reopenEffectiveDate) : null}
                        setValue={(value) => setReopenEffectiveDate(value)}
                      />
                    </div>
                  )}
                </StandardModal>
              </ModalContext.Provider>
            </>
          )}
        </div>
      </div>

      {pdfDownloadModal}
    </div>
  );
};

export default FormSubmit;
