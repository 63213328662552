import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button, { ButtonSize, ButtonType } from '../../shared/form-control/Button';
import PlusIcon from '../../shared/icon/PlusIcon';
import { deDuplicate } from '../../../utils/ListUtils';
import { FormType } from '../../../models/FormTypes';
import FileIcon from '../../shared/icon/FileIcon';
import ResourceIcon from '../../shared/icon/ResourceIcon';
import LanguageUtils from '../../../utils/LanguageUtils';
import ContextMenu from '../../shared/ContextMenu';
import { Link } from 'react-router-dom';
import { clientFormRoute } from '../../../utils/NavigationUtils';
import ReferenceDocumentModal from '../../documents/reference-document/ReferenceDocumentModal';
import { DocumentReferenceAnswer } from '../action-types/document-reference/DocumentReferenceAction';
import ClientFormService from '../../../services/ClientFormService';
import { EventSystem } from '../../../events/EventSystem';
import { Associations } from '../../../models/Association';
import StringUtils from '../../../utils/StringUtils';
import Tooltip from '../../shared/Tooltip';
import DocumentSpaceIcon from '../../shared/icon/DocumentSpaceIcon';
import { useCurrentUser } from '../../../global-state/Auth';

type Props = {
  clientFormId: string;
  associations: Associations[];
  viewOnly?: boolean;
};

const CondensedAssociations: FC<Props> = (props) => {
  const { clientFormId, associations, viewOnly } = props;
  const { t } = useTranslation('form');
  const currentUser = useCurrentUser((x) => x.value);
  const [modalOpen, setModalOpen] = useState(false);

  const references = useMemo(() => {
    return deDuplicate(
      associations.flatMap((x) => x.associations),
      'id',
    ).sort((a, b) => a.subtitle?.localeCompare(b.subtitle));
  }, [associations]);

  const refrenceIcon = useCallback((item: Associations['associations'][number]) => {
    switch (item.type) {
      case FormType.Document:
        return <DocumentSpaceIcon className="h-7 w-7" />;
      case FormType.Resource:
        return <ResourceIcon className="h-7 w-7" />;
      default:
        return <FileIcon className="h-7 w-7" />;
    }
  }, []);

  const selectedDocuments = useMemo(() => {
    return associations.flatMap((x) => x.associations) || [];
  }, [associations]);

  const disabledDocuments = useMemo(() => {
    return selectedDocuments.filter((x) => x.isTarget || x.isFormAnswer);
  }, [selectedDocuments]);

  const onReferenceDone = useCallback(
    (documentIds: DocumentReferenceAnswer[]) => {
      // De-duplicate answer
      const newAnswerSeen: Record<string, boolean> = {};
      const response = associations.flatMap((x) => x.associations);
      const deDuplicatedAnswer = [...(response || []), ...documentIds].filter((x) => (newAnswerSeen[x.id] ? false : (newAnswerSeen[x.id] = true)));
      // Now remove any answers that was de-selected in the pick-list
      const answer = deDuplicatedAnswer.filter((x) => !!documentIds.find((doc) => doc.id === x.id));

      const newItems = answer.filter((x) => !response?.find((response) => x.id === response.id));
      const removedItems = (response || []).filter((id) => !answer.find((response) => id === response));

      Promise.all([
        ClientFormService.createReference(
          clientFormId,
          newItems.map((x) => x.id),
          false,
        ),
        ...removedItems.map((x) => ClientFormService.removeReference(clientFormId, x.id)),
      ]).then(() => {
        EventSystem.fireEvent('associations-updated', null);
      });
    },
    [associations, clientFormId],
  );

  return (
    <div className="mt-4">
      <div className="sticky top-0 z-50 flex items-center justify-between gap-2 border-b bg-white px-4 pb-2">
        <div className="font-medium">{t('left-tabs.references.heading')}</div>
        {!viewOnly && (
          <Button type={ButtonType.SECONDARY} size={ButtonSize.S} onClick={() => setModalOpen(true)}>
            <div className="flex items-center gap-2">
              <PlusIcon className="h-4 w-4" /> {t('left-tabs.references.create-new-button')}
            </div>
          </Button>
        )}
      </div>

      {references.map((reference, i) => {
        let title =
          reference.type === FormType.Document && reference.documentNumber !== undefined
            ? `${StringUtils.makePrefixWithNumber(reference.prefix, reference.documentNumber, reference.templateModuleTranslations)}-`
            : '';
        title = `${title}${reference.subtitle || LanguageUtils.getTranslation('title', reference.translations)}`;
        return (
          <Link
            to={reference.canView ? clientFormRoute(reference, currentUser) : ''}
            key={reference.id}
            className={`hover:bg-background-1 flex items-center gap-2 py-3 pr-1 transition-colors ${
              reference.canView ? 'cursor-pointer' : 'cursor-not-allowed'
            } ${i === 0 ? '' : 'border-t'}`}
          >
            <div className="mx-2 flex-shrink-0">{refrenceIcon(reference)}</div>
            <div className="flex-grow truncate">
              <Tooltip text={title} truncatedTextMode>
                {(tooltip) => {
                  return (
                    <div {...tooltip} className="class flex items-center justify-between">
                      <div className="truncate font-medium underline">
                        {reference.type === FormType.Document &&
                          `${StringUtils.makePrefixWithNumber(
                            reference.prefix,
                            reference.documentNumber || 0,
                            reference.templateModuleTranslations,
                          )}-`}
                        {reference.subtitle || LanguageUtils.getTranslation('title', reference.translations)}
                      </div>
                      <div className="text-dpm-12 pl-1">v{reference.majorVersion}</div>
                    </div>
                  );
                }}
              </Tooltip>

              <div className="text-dpm-14">
                {reference.isSystemTemplateForm
                  ? LanguageUtils.getTranslation('name', reference.templateModuleTranslations)
                  : LanguageUtils.getTranslation('title', reference.translations)}
              </div>
            </div>
            <div className="flex-shrink-0">
              <ContextMenu items={[]} />
            </div>
          </Link>
        );
      })}
      {references.length === 0 && <div className="flex h-48 items-center justify-center">{t('left-tabs.references.empty-state')}</div>}

      <ReferenceDocumentModal
        open={modalOpen}
        selectedItems={selectedDocuments}
        disabledItems={disabledDocuments}
        onClose={() => setModalOpen(false)}
        onComplete={onReferenceDone}
      />
    </div>
  );
};

export default CondensedAssociations;
