import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { ClientFormUserRole } from '../../models/ClientFormUserRoles';
import { CommentRequest, CommentResponse } from '../../models/Comment';
import { ClientFormUser } from '../../models/ClientFormUser';
import User, { MinimalUser } from '../../models/User';
import ClientService from '../../services/ClientService';
import ClientFormService from '../../services/ClientFormService';
import CommentUtils from '../../utils/CommentUtils';
import PermissionsModal from '../ownership/PermissionsModal';
import Button, { ButtonType } from '../shared/form-control/Button';
import Checkbox from '../shared/form-control/Checkbox';
import MultiTextField from '../shared/form-control/MultiTextField';
import Mention from '../shared/mention/Mention';
import ObjectUtils from '../../utils/ObjectUtils';
import { EventSystem } from '../../events/EventSystem';
import CommentEvent from '../../events/QuestionCommentEvent';
import { nextTick } from '../../utils/ReactUtils';
import { useCommentsHub } from '../../contexts/signalR/CommentContext';
import { CommentCallbacksNames, CommentMethodNames } from '../../hubs/CommentHub';
import useOnlyHasPermission from '../../hooks/permissions/useOnlyHasPermission';
import { Roles } from '../../models/Role';
import { useCurrentClient } from '../../global-state/Clients';
import { useCurrentUser } from '../../global-state/Auth';
import { useCurrentFormUsers, useFormSectionUsers, useHidePrivateComments } from '../../global-state/Forms';
import { useShallow } from 'zustand/react/shallow';

interface Mention {
  tag: string;
  id: string;
  displayName: string;
  taskAssigned: boolean;
  taskResolved: boolean;
}

interface CommentEditorProps {
  editingComment: CommentResponse | null;
  clientId: string;
  clientFormId: string;
  disabled?: boolean;
  inThread: boolean;
  formStepId: string;
  handleCommentSave: (comment: CommentRequest) => Promise<void>;
  handleCommentCancel: () => void;
  onCommentAdded?: () => void;
}

const CommentEditor = ({
  editingComment,
  clientId,
  clientFormId,
  handleCommentSave,
  handleCommentCancel,
  onCommentAdded,
  disabled,
  inThread,
  formStepId,
}: CommentEditorProps): JSX.Element => {
  const [value, setValue] = useState('');
  const [active, setActive] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [suggestionTerm, setSuggestionTerm] = useState('');
  const [mentions, setMentions] = useState<Mention[]>([]);
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const { t } = useTranslation('comments');
  const currentClient = useCurrentClient((x) => x.value);
  const [clientUsers, setClientUsers] = useState<User[]>([]);
  const [formSectionUsers, setFormSectionUsers] = useFormSectionUsers(useShallow((x) => [x.value, x.setValue]));
  const formUsers = useCurrentFormUsers((x) => x.value);
  const [showPermisionsModal, setShowPermisionsModal] = useState(false);
  const [newFormUsers, setNewFormUsers] = useState<ClientFormUser[]>([]);
  const [isPrivate, setIsPrivate] = useState(editingComment?.isPrivate || false);
  const [actionId, setActionId] = useState<string | undefined>(undefined);
  const [isSaving, setIsSaving] = useState(false);
  const currentUser = useCurrentUser((x) => x.value);
  const hidePrivateComments = useHidePrivateComments((x) => x.value);
  const hasOnlyPermission = useOnlyHasPermission();

  const { useSignalREffect, connection, connected } = useCommentsHub();

  useEffect(() => {
    const handler = (event: CommentEvent) => {
      setActive(!!event.sourceId);
      setActionId(event.sourceId);

      setTimeout(() => {
        textAreaRef.current?.focus();
      }, 10);
    };

    EventSystem.listen('question-comment-new', handler);
    EventSystem.listen('question-comment-open', handler);

    return () => {
      EventSystem.stopListening('question-comment-new', handler);
      EventSystem.stopListening('question-comment-open', handler);
    };
  }, []);

  useEffect(() => {
    if (currentClient && active) {
      ClientService.getUsers().then((res) => {
        setClientUsers(res);
      });
    }
  }, [active, currentClient]);

  useEffect(() => {
    setValue(editingComment?.text || '');
    setMentions(
      (editingComment?.users || []).map(({ id, firstName, lastName, taskAssigned, taskResolvedUtc }) => ({
        id: id || '',
        tag: CommentUtils.generateTag([firstName, lastName]),
        displayName: `${firstName} ${lastName}`,
        taskAssigned,
        taskResolved: !!taskResolvedUtc,
      })),
    );
  }, [editingComment]);

  /**
   * Clears comment and mentions
   */
  function cancel() {
    handleCommentCancel();
    setValue('');
    setMentions([]);
    setActive(false);
    setIsPrivate(false);
    setShowSuggestions(false);
  }

  const usersMentioned = useMemo(
    () =>
      mentions.map(({ id, taskAssigned }) => ({
        userId: id,
        assignTask: taskAssigned,
      })),
    [mentions],
  );

  /**
   * Fires parent submit
   */
  async function onCreateCommentClick() {
    const newFormUsersAdded =
      usersMentioned.length > 0 &&
      formSectionUsers
        .filter((x) => x.formSectionId === formStepId)
        .map((x) => x.id || '')
        .concat(formUsers.map((x) => x.id || ''))
        .filter((e) => usersMentioned.map((x) => x.userId).includes(e)).length === 0;
    if (newFormUsersAdded && !hasOnlyPermission(Roles.ExternalAuditor)) {
      setNewFormUsers(
        clientUsers
          .filter((x) => usersMentioned.map((x) => x.userId).includes(x.id || ''))
          .map((x) => ({ ...x, role: ClientFormUserRole.Contributor, formSectionId: formStepId })),
      );
      nextTick(() => setShowPermisionsModal(true));
    } else {
      return saveComment();
    }
  }

  const saveComment = () => {
    const requestObj: CommentRequest = {
      clientId,
      clientFormId,
      sourceId: actionId,
      users: usersMentioned,
      text: value,
      isPrivate,
      formSectionId: formStepId,
    };
    setIsSaving(true);
    return handleCommentSave(requestObj)
      .then(() => {
        setValue('');
        setMentions([]);
        setActive(false);
        setIsPrivate(false);
        if (actionId) {
          EventSystem.fireEvent('question-comment-open', { sourceId: actionId, sectionId: formStepId });
        }
        onCommentAdded && onCommentAdded();
      })
      .finally(() => {
        setIsSaving(false);
      });
  };

  /**
   * Handles change to textarea value
   * 1. Saves new textarea value
   * 2. Handles deleted mentions
   * 3. Determines whether current word is a valid mention and displays suggestions
   * @param event The HTML text area change event
   */
  function handleChange(event: ChangeEvent<HTMLTextAreaElement>) {
    setActive(true);
    const { value } = event.target;
    setValue(value);

    handleMentionDelete(value);
    const { selectionStart } = event.target;

    // determine current word
    let currentWord = '';
    let start = 0;
    const lines = value.split(/[\n]/u);
    for (let l = 0; l < lines.length; l++) {
      const words = lines[l].split(/[\s]/u);
      for (let w = 0; w < words.length; w++) {
        const isCurrentWord = selectionStart >= start && selectionStart <= start + words[w].length;
        if (isCurrentWord) {
          currentWord = words[w];
          break;
        }
        start += words[w].length + 1;
      }
      if (currentWord) break;
    }

    // Show suggestions if current word begins with @ and contains no non-alphanumeric characters except @ - '
    if (currentWord.startsWith('@') && !currentWord.match(/[^\p{L}\d\s'@-]/u)) {
      setShowSuggestions(true);
      setSuggestionTerm(currentWord.slice(1, currentWord.length));

      return;
    }
    setShowSuggestions(false);
    setSuggestionTerm('');
    if (currentUser?.firstName) {
      connection?.invoke(CommentMethodNames.IsTyping, currentUser?.firstName);
    }
  }

  /**
   * Adds selected user to comment as a mention
   * 1. Locates current mention in textarea and replaces with full tag
   * 2. Saves mention to state if it doesn't already exist
   */
  function handleUserSelected(user: MinimalUser) {
    const id = user.id || '';
    const tag = CommentUtils.generateTag([user.firstName, user.lastName]);

    const selectionStart = textAreaRef.current?.selectionStart;

    // Locate and format current mention
    if (selectionStart) {
      const lines = value.split(/[\n]/u);
      const newLines: string[] = [];
      let start = 0;
      for (let l = 0; l < lines.length; l++) {
        const words = lines[l].split(/[\s]/u);
        for (let w = 0; w < words.length; w++) {
          const isCurrentSuggestionTerm = selectionStart >= start && selectionStart <= start + words[w].length;
          start += words[w].length + 1;
          if (isCurrentSuggestionTerm) {
            words[w] = tag + ' ';
            break;
          }
        }
        newLines.push(words.join(' '));
      }
      setValue(newLines.join('\n'));

      // Save mention
      const clonedMentions = ObjectUtils.DeepClone(mentions);
      const exists = clonedMentions.find((mention: Mention) => mention.id === id);
      if (!exists) {
        clonedMentions.push({
          tag,
          id,
          displayName: `${user.firstName} ${user.lastName}`,
          taskAssigned: false,
          taskResolved: false,
        });
        setMentions(clonedMentions);
      }
      // TODO: Focus to specific caret position
      textAreaRef.current?.focus();
      setShowSuggestions(false);
      setSuggestionTerm('');
    }
  }

  /**
   * Delete unused mentions
   * @param value Current textarea value
   */
  function handleMentionDelete(value: string) {
    const currentTextAreaValue = value;

    const newMentions: Mention[] = [];

    for (let i = 0; i < mentions.length; i++) {
      const exists = currentTextAreaValue.includes(mentions[i].tag);
      if (exists) newMentions.push(mentions[i]);
    }

    setMentions(newMentions);
  }

  const [suggestionSelectedIndex, setSuggestionSelectedIndex] = useState(0);
  const filteredSuggestions = useMemo(() => {
    return clientUsers
      .filter((user) => {
        if (suggestionTerm === '') {
          return true;
        }
        const value = suggestionTerm.toLowerCase();
        const name = (user.firstName || '').replaceAll(' ', '').toLowerCase();
        const surname = (user.lastName || '').replaceAll(' ', '').toLowerCase();
        const fullName = `${name} ${surname}`;

        return `${name}${surname}`.indexOf(value) > -1 || name.indexOf(value) > -1 || surname.indexOf(value) > -1 || fullName.indexOf(value) > -1;
      })
      .map((user) => {
        return {
          value: `${user.firstName} ${user.lastName}`,
          text: `${user.firstName} ${user.lastName}`,
          role: ClientFormUserRole.Viewer,
          ...user,
        } as ClientFormUser;
      });
  }, [clientUsers, suggestionTerm]);

  const onKeyPress = (event: KeyboardEvent): void => {
    const key = event.key;
    if (key === 'ArrowDown' && showSuggestions) {
      event.preventDefault();
      const newIndex = suggestionSelectedIndex + 1;
      if (newIndex < filteredSuggestions.length) {
        setSuggestionSelectedIndex(newIndex);
      }
    } else if (key === 'ArrowUp' && showSuggestions) {
      event.preventDefault();
      const newIndex = suggestionSelectedIndex - 1;
      if (newIndex >= 0) {
        setSuggestionSelectedIndex(newIndex);
      }
    } else if ((key === 'Enter' || key === 'Tab') && showSuggestions) {
      if (filteredSuggestions[suggestionSelectedIndex]) {
        event.preventDefault();
        handleUserSelected(filteredSuggestions[suggestionSelectedIndex]);
        setSuggestionSelectedIndex(0);
        setShowSuggestions(false);
      }
    } else if (key === 'Enter' && event.ctrlKey) {
      onCreateCommentClick();
    }
  };

  /**
   * Sets the assignTask property of the provided mention to the provided value
   * @param index Index of mention
   * @param value Value to set assignTask to
   */
  function assignTask(index: number, value: boolean) {
    const clonedMentions = [...mentions];
    clonedMentions[index].taskAssigned = value;
    setMentions(clonedMentions);
  }

  const changeUserFormRole = (changedUsers: ClientFormUser[]) => {
    const usersToUpdate = [
      ...newFormUsers.map((x) => {
        const y = changedUsers.find((j) => j.formSectionId === x.formSectionId && j.id == x.id);
        return y ? { ...x, ...y } : x;
      }),
      ...formSectionUsers.map((x) => {
        const y = changedUsers.find((j) => j.formSectionId === x.formSectionId && j.id === x.id);
        return y ? { ...x, ...y } : x;
      }),
    ];
    return ClientFormService.addOrUpdateFormUsers(
      clientFormId,
      usersToUpdate.filter((x) => x.formSectionId === formStepId),
      formStepId,
    ).then(() => {
      saveComment();
      setFormSectionUsers(usersToUpdate);
      setNewFormUsers((prevNewFormUsers) => {
        return [
          ...prevNewFormUsers.map((x) => {
            const y = changedUsers.find((j) => j.id === x.id);
            return y ? { ...x, ...y } : x;
          }),
        ];
      });
    });
  };

  const userTimers = useRef<Record<string, number>>({});
  const [usersTyping, setUsersTyping] = useState<string[]>([]);

  useSignalREffect(
    CommentCallbacksNames.NotifyIsTyping,
    (username: string) => {
      if (username !== currentUser?.firstName) {
        if (userTimers.current[username]) {
          clearTimeout(userTimers.current[username]);
        }
        setUsersTyping((prev) => {
          if (!prev.find((x) => x === username)) {
            return [...prev, username];
          }
          return prev;
        });

        const timeoutId = window.setTimeout(() => {
          setUsersTyping((prev) => prev.filter((x) => x !== username));
        }, 5000);

        userTimers.current[username] = timeoutId;
      }
    },
    [],
  );

  useEffect(() => {
    if (editingComment) {
      setIsPrivate(editingComment.isPrivate);
      setActive(true);
      textAreaRef.current?.focus();
    }
  }, [editingComment]);

  return (
    <div className="bg-background-1 flex flex-col pb-4 pl-2 pr-6 pt-2" data-cy="comment-editor">
      <div className="text-dpm-14 relative">
        <div id="more-comments-portal"></div>
        {showSuggestions && (
          <Mention
            textBox={textAreaRef}
            selectedIndex={suggestionSelectedIndex}
            formUsers={filteredSuggestions}
            onUserSelected={handleUserSelected}
          />
        )}
        {!mentions.length && active && (
          <p className="text-dpm-14 px-1" data-cy="mention-hint">
            {t('mention-hint')}
          </p>
        )}
        <label id="comment-editor-label" htmlFor="comment-editor" className="sr-only">
          {t('create')}
        </label>
        <MultiTextField
          aria-labelledby="comment-editor-label"
          data-cy="comment-text-area"
          id="comment-editor"
          innerRef={textAreaRef}
          className="border-gray-5 relative h-full w-full rounded border-2"
          value={value}
          onChange={handleChange}
          onFocus={() => setActive(true)}
          placeholder={t('input-placeholder')}
          onKeyPress={onKeyPress}
          rows={2}
          maxLength={1000}
          hideMaxLengthCounter
        />
        {usersTyping?.length > 0 && (
          <span className="text-dpm-14 px-1">{usersTyping.length > 2 ? t('several-typing') : t('typing', { user: usersTyping?.join(', ') })}</span>
        )}
        {mentions.map(({ taskAssigned, displayName }, index) => (
          <div key={index} className="flex flex-col">
            <div>
              <Checkbox
                data-cy="assign-task-to-user"
                className="text-dpm-14"
                value={taskAssigned}
                label={t('assign', { name: displayName })}
                onChange={(newValue: boolean) => assignTask(index, newValue)}
              />
            </div>
          </div>
        ))}
        {active && (
          <>
            {!inThread && (
              <>
                <Checkbox
                  data-cy="mark-thread-private"
                  className="text-dpm-14"
                  value={isPrivate}
                  onChange={setIsPrivate}
                  label={t('thread.mark-private')}
                />
                {isPrivate && hidePrivateComments && (
                  <p className="text-dpm-14 px-1" data-cy="filter-warning">
                    <Trans
                      t={t}
                      i18nKey="private-filter-warning"
                      components={{
                        bold: <span className="font-medium" />,
                      }}
                    />
                  </p>
                )}
              </>
            )}
            <div className="flex justify-between py-2">
              <Button data-cy="cancel-comment" onClick={() => cancel()} type={ButtonType.SECONDARY}>
                {t('buttons.cancel')}
              </Button>
              <Button
                data-cy="make-comment"
                onClick={() => onCreateCommentClick()}
                type={ButtonType.PRIMARY}
                disabled={(value.length || 0) === 0 || isSaving || disabled || !connected}
              >
                {t('buttons.comment')}
              </Button>
            </div>
          </>
        )}
      </div>
      <PermissionsModal
        heading={t('permissions-modal.heading')}
        description={t('permissions-modal.description')}
        users={newFormUsers}
        open={showPermisionsModal}
        onClose={() => setShowPermisionsModal(false)}
        disableAdd={true}
        onAddorUpdate={changeUserFormRole}
        formStepId={formStepId}
        requiresApproval={false}
        requiresValidation={false}
        excludeOwnerRole={true}
      />
    </div>
  );
};

export default CommentEditor;
