import { Dispatch, FC, SetStateAction, useCallback, useMemo, useState } from 'react';
import { DistributionMemberStatus, DistributionResponse, PeopleType } from '../../models/Distribution';
import useFetchClientContacts from '../../hooks/useFetchClientContacts';
import useFetchClientUsers from '../../hooks/useFetchClientUsers';
import useFetchClientTags from '../../hooks/useFetchClientTags';
import DropdownSelect, { DropdownSize, useDropdownSelect } from '../shared/form-control/DropdownSelect';
import { useTranslation } from 'react-i18next';
import LanguageUtils from '../../utils/LanguageUtils';
import { Option } from '../Option';
import ProfileInitials from '../shared/profile-image/ProfileInitials';
import Checkbox from '../shared/form-control/Checkbox';
import StringUtils from '../../utils/StringUtils';
import CheckIcon from '../shared/icon/CheckIcon';
import DistributionService from '../../services/DistributionService';
import { recordMap, toGroupedRecord } from '../../utils/ListUtils';
import { ClientContactResponse } from '../../models/ClientContacts';
import { EventSystem } from '../../events/EventSystem';
import ClientTagService from '../../services/ClientTagService';
import { MenuListProps, components } from 'react-select';
import { ButtonSize, ButtonType } from '../shared/form-control/Button';
import PlusIcon from '../shared/icon/PlusIcon';
import DropdownButton from '../shared/form-control/DropdownButton';
import InviteOrSearchModal from '../user/InviteOrSearchModal';
import UserInvitation from '../../models/UserInvitation';
import ClientService from '../../services/ClientService';
import AddContactModal from '../contacts/AddContactModal';
import { ModalContext } from '../../contexts/ModalContext';
import ConfirmationModal from '../shared/modal/variants/ConfirmationModal';
import { useCurrentClient } from '../../global-state/Clients';
import ProfileAvatar, { ImageSize } from '../shared/profile-image/ProfileAvatar';
import User from '../../models/User';

type Props = {
  distribution: DistributionResponse;
};

const BIT_EMPTY = 0b00000000;
const BIT_IS_MEMBER_OF_DISTIBUTION = 0b00000001;
const BIT_IS_ACKNOWLEDGED_OR_SIGNED = 0b00000010;
const BIT_CHANGED = 0b00000100;
const BIT_CHANGED_VALUE = 0b00001000;

const hasBit = (value: number, bit: number) => (value & bit) === bit;

const DistributionMembersDropdown: FC<Props> = (props) => {
  const { distribution } = props;
  const { data: contacts, refetch: refetchContacts } = useFetchClientContacts();
  const { data: clientUsers, refetch: refetchClientUsers } = useFetchClientUsers();
  const { data: tags } = useFetchClientTags();
  const {
    t,
    i18n: { language },
  } = useTranslation('distribution');
  const currentClient = useCurrentClient((x) => x.value);
  const [changedMembers, setChangedMembers] = useState<Record<string, number>>({});
  const [saving, setSaving] = useState(false);
  const [inviteModal, setInviteModal] = useState<typeof PLATFORM | typeof EXTERNAL | null>(null);
  const [warnModal, setWarnModal] = useState<string | null>(null);
  const [forcedOpen, setForcedOpen] = useState(false);

  const calculateBitsForMember = useCallback(
    (memberId: string) => {
      if (memberId in changedMembers) return changedMembers[memberId];

      const member = distribution.members.find((x) => x.memberId === memberId);
      if (!member) return BIT_EMPTY;

      return (
        BIT_IS_MEMBER_OF_DISTIBUTION |
        (!(memberId in changedMembers) ? BIT_EMPTY : BIT_CHANGED_VALUE) |
        ([DistributionMemberStatus.Acknowledged, DistributionMemberStatus.Signed].includes(member.status) ? BIT_IS_ACKNOWLEDGED_OR_SIGNED : BIT_EMPTY)
      );
    },
    [changedMembers, distribution],
  );

  const options = useMemo(() => {
    const everyone = [
      ...(clientUsers ?? [])
        .map((x) => ({ id: x.id, text: `${x.firstName} ${x.lastName}`, value: calculateBitsForMember(x.id), group: 'internal' }))
        .sort((a, b) => a.text.localeCompare(b.text)),

      ...(contacts ?? [])
        .map((x) => ({ id: x.id, text: `${x.firstName} ${x.lastName}`, value: calculateBitsForMember(x.id), group: 'external' }))
        .sort((a, b) => a.text.localeCompare(b.text)),

      ...(tags ?? [])
        .map((x) => ({
          id: x.id,
          text: LanguageUtils.getTranslation('name', x.translations, language),
          value: calculateBitsForMember(x.id),
          group: 'external',
        }))
        .sort((a, b) => a.text.localeCompare(b.text)),
    ];

    return [
      {
        label: t('left-panel.groups.distribution-members'),
        options: everyone.filter((x) => hasBit(x.value, BIT_IS_MEMBER_OF_DISTIBUTION)),
      },
      {
        label: t('left-panel.groups.client-users'),
        options: everyone.filter((x) => !hasBit(x.value, BIT_IS_MEMBER_OF_DISTIBUTION) && x.group === 'internal'),
      },
      {
        label: t('left-panel.groups.contact-users'),
        options: everyone.filter((x) => !hasBit(x.value, BIT_IS_MEMBER_OF_DISTIBUTION) && x.group === 'external'),
      },
    ];
  }, [calculateBitsForMember, clientUsers, contacts, language, t, tags]);

  const onChange = useCallback((option: Option<string, number>) => {
    if (hasBit(option.value, BIT_IS_ACKNOWLEDGED_OR_SIGNED)) return;

    if (hasBit(option.value, BIT_IS_MEMBER_OF_DISTIBUTION)) {
      if (hasBit(option.value, BIT_CHANGED) ? hasBit(option.value, BIT_CHANGED_VALUE) : hasBit(option.value, BIT_IS_MEMBER_OF_DISTIBUTION)) {
        setWarnModal(option.id);
        setForcedOpen(true);
        return;
      }
    }

    setChangedMembers((prev) => ({
      ...prev,
      [option.id]:
        (hasBit(option.value, BIT_CHANGED)
          ? hasBit(option.value, BIT_CHANGED_VALUE)
            ? BIT_EMPTY
            : BIT_CHANGED_VALUE
          : hasBit(option.value, BIT_IS_MEMBER_OF_DISTIBUTION)
            ? BIT_EMPTY
            : BIT_CHANGED_VALUE) |
        BIT_CHANGED |
        (hasBit(option.value, BIT_IS_MEMBER_OF_DISTIBUTION) ? BIT_IS_MEMBER_OF_DISTIBUTION : BIT_EMPTY),
    }));
  }, []);

  const onWarnConfirm = useCallback(() => {
    setWarnModal(null);
    setTimeout(() => {
      setForcedOpen(false);
    }, 10);

    const option = options[0].options.find((x) => x.id === warnModal)!;
    setChangedMembers((prev) => ({
      ...prev,
      [option.id]:
        (hasBit(option.value, BIT_CHANGED)
          ? hasBit(option.value, BIT_CHANGED_VALUE)
            ? BIT_EMPTY
            : BIT_CHANGED_VALUE
          : hasBit(option.value, BIT_IS_MEMBER_OF_DISTIBUTION)
            ? BIT_EMPTY
            : BIT_CHANGED_VALUE) |
        BIT_CHANGED |
        (hasBit(option.value, BIT_IS_MEMBER_OF_DISTIBUTION) ? BIT_IS_MEMBER_OF_DISTIBUTION : BIT_EMPTY),
    }));
  }, [options, warnModal]);

  const onWarnClose = useCallback(() => {
    setWarnModal(null);
    setTimeout(() => {
      setForcedOpen(false);
    }, 10);
  }, []);

  const onSave = useCallback(async () => {
    if (warnModal) return;

    setSaving(true);
    const initialySelectedMembers = new Set(distribution.members.map((x) => x.memberId));
    const removedMembers = Object.keys(changedMembers).filter((x) => initialySelectedMembers.has(x) && !hasBit(changedMembers[x], BIT_CHANGED_VALUE));
    const addedMembers = Object.keys(changedMembers).filter((x) => !initialySelectedMembers.has(x) && hasBit(changedMembers[x], BIT_CHANGED_VALUE));

    if (addedMembers.length === 0 && removedMembers.length === 0) {
      setSaving(false);
      return;
    }

    let payloadMembers = recordMap(
      toGroupedRecord(
        distribution.members.filter((x) => !removedMembers.includes(x.memberId)),
        'type',
      ),
      (_, value) => value.map((x) => x.memberId),
    );

    for (const member of addedMembers) {
      payloadMembers[PeopleType.Member] ??= [];
      payloadMembers[PeopleType.Contact] ??= [];

      const tag = tags?.find((x) => x.id === member);
      const clientUser = clientUsers?.some((x) => x.id === member);

      if (tag) {
        const res = await ClientTagService.getTag<ClientContactResponse>(currentClient!.id, tag.id);
        payloadMembers[PeopleType.Contact].push(...res.data.items.map((x) => x.id));
      } else {
        const memberType = clientUser ? PeopleType.Member : PeopleType.Contact;
        payloadMembers[memberType].push(member);
      }
    }

    payloadMembers = recordMap(payloadMembers, (_, value) => [...new Set(value)]);

    DistributionService.updateDistribution(distribution.id, { ...distribution, members: payloadMembers }).then((res) => {
      EventSystem.fireEvent('distribution-updated', res.data);
      setChangedMembers({});
      setSaving(false);
    });
  }, [changedMembers, clientUsers, currentClient, distribution, tags, warnModal]);

  const onInviteNew = useCallback(
    (user: UserInvitation) => {
      ClientService.inviteUser(currentClient!.id, user).then((res) => {
        const payloadMembers = recordMap(toGroupedRecord(distribution.members, 'type'), (_, value) => value.map((x) => x.memberId));
        payloadMembers[PeopleType.Member] ??= [];
        payloadMembers[PeopleType.Member].push(res.data.userId);

        DistributionService.updateDistribution(distribution.id, {
          ...distribution,
          members: payloadMembers,
        }).then((res) => {
          EventSystem.fireEvent('distribution-updated', res.data);
          setInviteModal(null);
          refetchClientUsers();
        });
      });
    },
    [currentClient, distribution, refetchClientUsers],
  );

  const onInvitedContact = useCallback(
    (contact: ClientContactResponse) => {
      const payloadMembers = recordMap(toGroupedRecord(distribution.members, 'type'), (_, value) => value.map((x) => x.memberId));
      payloadMembers[PeopleType.Contact] ??= [];
      payloadMembers[PeopleType.Contact].push(contact.id);

      DistributionService.updateDistribution(distribution.id, {
        ...distribution,
        members: payloadMembers,
      }).then((res) => {
        EventSystem.fireEvent('distribution-updated', res.data);
        setInviteModal(null);
        refetchContacts();
      });
    },
    [distribution, refetchContacts],
  );

  const MenuList = useMemo(() => MenuListWrapper({ openModal: setInviteModal }), [setInviteModal]);

  return (
    <>
      <DropdownSelect
        options={options}
        onChange={onChange}
        value={null}
        customListRenderer={ListRenderer}
        closeOnSelect={false}
        onClose={onSave}
        searchable
        size={DropdownSize.S}
        className="mt-1"
        placeholder={t('left-panel.input-placeholder')}
        isFetching={saving}
        disabled={saving}
        customComponents={{
          DropdownIndicator: Chevron,
          MenuList: MenuList,
        }}
        forceOpen={!!warnModal || forcedOpen}
      />

      <InviteOrSearchModal
        open={inviteModal === 'platform'}
        onClose={() => setInviteModal(null)}
        clientId={currentClient!.id}
        onInviteNew={onInviteNew}
      />
      <AddContactModal open={inviteModal === 'external'} onClose={() => setInviteModal(null)} contactCreated={onInvitedContact} />

      <ModalContext.Provider value={{ open: !!warnModal, modalWidth: 'w-2/5', onClose: onWarnClose }}>
        <ConfirmationModal
          onConfirm={onWarnConfirm}
          onCancel={onWarnClose}
          title={t('delete-member.title')}
          description={t('delete-member.description')}
          confirmText={t('delete-member.confirm')}
        />
      </ModalContext.Provider>
    </>
  );
};

export default DistributionMembersDropdown;

const ListRenderer: FC<Option<string, number>> = (props) => {
  const { id, text, value } = props;
  const { inputValue } = useDropdownSelect();

  const { data: contacts } = useFetchClientContacts();
  const { data: clientUsers } = useFetchClientUsers();
  const { data: tags } = useFetchClientTags();
  const { t } = useTranslation('distribution');

  const icon = useMemo(() => {
    const internalMember = clientUsers?.find((x) => x.id === id);

    if (internalMember) {
      return { ...internalMember };
    }

    const externalMember = contacts?.find((x) => x.id === id);
    if (externalMember) {
      return { id: undefined, firstName: externalMember?.firstName ?? '', lastName: externalMember?.lastName ?? '' };
    }

    return null;
  }, [clientUsers, contacts, id]);

  const checkedValue = useMemo(() => {
    if (hasBit(value, BIT_CHANGED)) {
      return hasBit(value, BIT_CHANGED_VALUE);
    }

    return hasBit(value, BIT_IS_MEMBER_OF_DISTIBUTION);
  }, [value]);

  const tagMemberCount = useMemo(() => {
    const tag = tags?.find((x) => x.id === id);
    if (!tag) return '';

    const text = t('left-panel.group-member-count', { count: tag.itemsCount });

    return <span className="text-gray-2"> • {text}</span>;
  }, [id, t, tags]);

  return (
    <div className={`flex items-center justify-between gap-2 ${hasBit(value, BIT_IS_ACKNOWLEDGED_OR_SIGNED) ? 'cursor-not-allowed' : ''}`}>
      <div className="flex items-center gap-2">
        <Checkbox value={checkedValue} disabled={hasBit(value, BIT_IS_ACKNOWLEDGED_OR_SIGNED)} stopEventPropogation={false} onChange={() => ({})} />
        {icon && <ProfileAvatar size={ImageSize.XS} user={icon as User} />}
        <span className="text-black">
          {StringUtils.highlightText(text, inputValue)} {tagMemberCount}
        </span>
      </div>
      <div>{hasBit(value, BIT_IS_ACKNOWLEDGED_OR_SIGNED) && <CheckIcon className="text-semantic-1 h-4 w-4" />}</div>
    </div>
  );
};

const Chevron = () => null;

const PLATFORM = 'platform';
const EXTERNAL = 'external';

const MenuListWrapper = (outerProps: {
  openModal: Dispatch<SetStateAction<'platform' | 'external' | null>>;
}): FC<MenuListProps<Option<string, number>>> =>
  function MenuList(props) {
    const { openModal } = outerProps;
    const { t } = useTranslation('distribution');

    const inviteOptions = useMemo<Option<string, string>[]>(() => {
      return [
        {
          id: PLATFORM,
          text: t('left-panel.add-members.platform'),
          value: PLATFORM,
        },
        {
          id: EXTERNAL,
          text: t('left-panel.add-members.external'),
          value: EXTERNAL,
        },
      ];
    }, [t]);

    const onSelect = useCallback(
      (option: Option<string, string | number>) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        openModal(option.id as any);
      },
      [openModal],
    );

    return (
      <components.MenuList {...props}>
        {props.children}
        <div className="sticky -bottom-[4.5px] z-50 -mb-2 bg-white p-2">
          <DropdownButton options={inviteOptions} onSelect={onSelect} type={ButtonType.SECONDARY} size={ButtonSize.S} enableChevron={false}>
            <DropdownButton.Slot name="Icon">
              <PlusIcon className="h-3 w-3" />
            </DropdownButton.Slot>
            {t('left-panel.add-members.button')}
          </DropdownButton>
        </div>
      </components.MenuList>
    );
  };
